<template>
    <div>
        <v-row v-if="$parent.$parent.get_permission('edit') && $parent.$parent.get_permission('show')">
            <v-col>
                <v-btn class="ma-1" large solo depressed color="secondary" @click="$refs.editor.show(false)">
                    <v-icon class="mr-2">person</v-icon>
                    <span class="subtitle-2">Añadir empleado</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="$parent.$parent.get_permission('show')">
            <v-col cols="12">
                <DashCard title="Empleados" body-class="pa-0">
                    <v-text-field v-model="busqueda" class="mx-4 my-4" solo outlined dense flat hide-details prepend-inner-icon="search" label="Buscar empleado..." />
                    <v-divider />
                    <v-skeleton-loader type="table" :loading="tabla_loading">
                        <v-data-table dense :search="busqueda" :headers="headers" :items="index_data" :items-per-page="15" :mobile-breakpoint="0">
                            <template v-slot:item.jefe.first_name="{ item }">
                                <v-chip v-if="item.jefe" dark color="primary">
                                    <v-icon left>account_circle</v-icon>
                                    {{ (item.jefe ? item.jefe.first_name + " " + item.jefe.last_name : "" ) }}
                                </v-chip>
                                <span v-else>N/A</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-menu offset-y v-if="$parent.$parent.get_permission('edit')">
                                    <template v-slot:activator="{ on }">
                                        <v-btn fab small text color="primary" depressed v-on="on">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                        <v-list-item @click="$refs.editor.show(true, item)">
                                            <v-list-item-icon>
                                                <v-icon>edit</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>

        <DialogEmpleado ref="editor" :empleados="index_data" @onSuccess="$parent.$parent.reloadComponent()" />
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import CommonDialog from '@/shared/components/CommonDialog'
import DialogEmpleado from '@/dash/dialogs/DialogEmpleado'
import AccessMessage from '@/dash/components/AccessMessage'
import { axiosHelper } from '@/helpers'

export default {
    components: {
        DashCard,
        CommonDialog,
        DialogEmpleado,
        AccessMessage
    },
    data: () => ({
        index_data: null,
        busqueda: null,
        headers: [
            { text: "Nombres", value: "first_name" },
            { text: "Apellidos", value: "last_name" },
            { text: "Cargo", value: "cargo" },
            { text: "Áreas", value: "areas" },
            { text: "Jefe", value: "jefe.first_name" },
            { text: "", value: "actions", width: '40px', sortable: false }
        ]
    }),
    computed: 
    {
        tabla_loading() {
            return !this.index_data;
        }
    },
    mounted() {
        this.$emit('title', { text: "Empleados", icon: "account_box" }); 

        if(!this.$parent.$parent.get_permission('show')) return;

        axiosHelper.get('personas?full')
            .then(response => {
                this.index_data = response.data;
            });
    }
}
</script>