<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard :title="dialog.title" actions-align="right">
            <v-form id="form" ref="creator_form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-label>Nombres*</v-label>
                        <v-text-field v-model="creator.empleado.first_name" solo flat dense outlined hide-details label="Nombres" :rules="[creation_rules.required]" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-label>Apellidos*</v-label>
                        <v-text-field v-model="creator.empleado.last_name" solo flat dense outlined hide-details label="Apellidos" :rules="[creation_rules.required]" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-label>Cargo*</v-label>
                        <v-text-field v-model="creator.empleado.cargo" solo flat dense outlined hide-details label="Cargo" :rules="[creation_rules.required]" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-label>Areas*</v-label>
                        <v-text-field v-model="creator.empleado.areas" solo flat dense outlined hide-details label="Areas" :rules="[creation_rules.required]" />
                    </v-col>
                    <v-col cols="12">
                        <v-label>Correo electrónico</v-label>
                        <v-text-field v-model="creator.empleado.email" solo flat dense outlined hide-details label="correo@ejemplo.com" />
                    </v-col>
                    <v-col cols="12" sm="9">
                        <v-label>Jefe</v-label>
                        <v-row dense>
                            <v-col cols="12" sm="8">
                                <v-autocomplete 
                                    solo flat dense outlined hide-details autocomplete="off" 
                                    v-model="creator.empleado.jefe" 
                                    :item-text="(i) => i.first_name + ' ' + i.last_name" 
                                    item-value="id"
                                    :return-object="false"
                                    :items="empleados" label="Jefe" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-btn depressed large color="primary" @click="creator.empleado.jefe = null">
                                    <span class="subtitle-2">Quitar propiedad</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-label>(*) Campos obligatorios</v-label>
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="uploadEmpleado">
                    <v-icon>done</v-icon>
                    <span class="ml-2 subtitle-2">{{ (creator.edit ? 'Guardar' : 'Añadir') }}</span>
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="dialog" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    props: ['empleados'],
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        dialog: {
            visible: false,
            title: ''
        },
        creator: {
            empleado: {},
            edit: false
        },
        creation_rules: {
            required: value => valid_required(value)
        }
    }),
    methods: {
        show(edit, user) {
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();

            this.dialog.visible = true;
            this.creator.edit = edit;

            if(this.creator.edit) {
                this.dialog.title = "Editar empleado/a";
                this.creator.empleado = { ... user };
            } else {
                this.dialog.title = "Nuevo empleado/a";
            }
        },
        close() {
            this.dialog.visible = false;
            this.creator.empleado = {};

            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
        },
        error(data) {
            let text = messages.texts["error_check_fields"];

            if(data) {
                text = data;
            }
            this.$refs.dialog.show(messages.titles["error_generico"], text, "error");
        },
        uploadEmpleado(e) 
        {
            e.preventDefault();
            if(!this.$refs.creator_form.validate()) {
                this.error();
                return;
            }
            
            if(this.creator.edit) {
                if(this.creator.empleado.jefe == this.creator.empleado.id) 
                {
                    this.error(messages.texts["error_propio_jefe"]);
                    return;
                }

                if(!!this.creator.empleado.jefe) {
                    if(typeof(this.creator.empleado.jefe) == "object") {
                        this.creator.empleado.jefe = this.creator.empleado.jefe.id;
                    }
                }

                axiosHelper.put('personas/' + this.creator.empleado.id + '/', this.creator.empleado)
                    .then(response => {
                        this.$emit('onSuccess');
                    })
                    .catch(error => this.error(error));
            } else {
                axiosHelper.post('personas/', this.creator.empleado)
                    .then(response => {
                        this.$emit('onSuccess');
                    })
                    .catch(error => this.error(error));
            }
        }
    }
}
</script>